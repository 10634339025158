import * as React from "react";
import { ReactElement, useEffect, useRef, useState } from 'react';
import {
  Box,
  Stack,
  Heading,
  Text,
  Image,
  Button,
  useColorModeValue,
  Container,
  useToast,
  Flex,
  SimpleGrid,
  StackDivider,
  SpaceProps,
  HStack,
  Tag,
  Link,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  Center,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Grid,
  GridItem,
  Switch,
  ButtonGroup,
  Spacer
} from "@chakra-ui/react";
import { PageSlideFade } from "../components/page-transitions";
import { apiHost, baseUrl } from "utils/Cons/Constants";
import axios from "axios";
import { Helmet } from "react-helmet";
import { FaAccessibleIcon, FaBullseye, FaSearch, FaVolumeUp } from "react-icons/fa";
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon, LatLng } from 'leaflet';
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { MotionBox } from "../components/motion";
import ReactMarkdown from 'react-markdown';
import headerBg from "../assets/images/header-background.jpg";
import cloudsBg from "../assets/images/cloud-background.png";
import { Field, Formik } from "formik";
import Masonry from "react-masonry-css";
import "../style/style.css";
import useSound from "use-sound";
import parser from "html-react-parser";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listMonth from "@fullcalendar/list";
import listWeek from "@fullcalendar/list";
import listDay from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import Slider from "react-slick";

const markdownTheme = {
  p: props => {
    const { children } = props;
    return (
      <Text mb={2} fontSize={'0.8em'}>
        {children}
      </Text>
    );
  },
};

const Eventcategory = ({ match }) => {
  const toast = useToast();

  const [grid, setGrid] = useState(true);
  const [breakpointColumns, setBreakpointColumns] = useState({});

  const [featuredEvents, setFeaturedEvents] = useState([]);
  const [searchValues, setSearchValues] = useState({});
  const [loading, setLoading] = useState(true);

  const [experiencesloading, setExperiencesLoading] = useState(false);
  const [experiences, setExperiences] = useState([]);

  const [activitiesloading, setActivitiesLoading] = useState(false);
  const [activities, setActivities] = useState([]);


  const [categoriesloading, setCategoriesLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const [selectedDestination, setSelectedDestination] = useState({});

  const [page, setPage] = useState({ fields: {} });

  const bgColor = useColorModeValue('white', 'gray.900');
  const headingColor = useColorModeValue('gray.700', 'white');

  const position = [40.7880456, -77.870124];

  const calendarRef = React.createRef();

  const [calendarDataCalendar, setCalendarData] = useState([]);

  // date picker
  const [selectedDates, setSelectedDates] = useState<Date[]>([new Date(), new Date()]);
  const propsConfigs = {
    inputProps: {
      borderRadius: "15px",
      size: "lg",
      fontSize: "sm"
    },
    dateNavBtnProps: {
      //variant: "outline"
    },
    dayOfMonthBtnProps: {
      defaultBtnProps: {
        borderColor: "tan.300",
        _hover: {
          background: 'blue.400',
        }
      },
      isInRangeBtnProps: {
        background: "blue.800",
        color: "tan",
      },
      selectedBtnProps: {
        background: "blue.800",
        color: "tan",
      },
      todayBtnProps: {
        borderColor: "blue.300",
        color: "blue.300",
        _hover: {
          color: 'blue.300',
        }
      }
    },
  };

  // drawers
  const { isOpen, onOpen, onClose } = useDisclosure()
  const firstField = useRef()

  const {
    isOpen: isFilterOpen,
    onOpen: onFilterOpen,
    onClose: onFilterClose
  } = useDisclosure()

  const initialMarkers = [];
  const [markers, setMarkers] = useState(initialMarkers);

  const breakpointColumnsObj = {
    default: 6,
    1300: 4,
    900: 1
  };

  const breakpointColumnsListObj = {
    default: 3
  };

  const [events, setEvents] = useState([]);

  const settingsSlickEvents = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: false,
    autoplay: true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  const LocationMarkers = () => {
    return (
      <React.Fragment>
        {markers.map(marker => <Marker
          position={marker.location}
          icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}
          eventHandlers={{
            click: (e) => {
              setSelectedDestination(marker.data);
              onOpen();
            },
          }}>
        </Marker>)}
      </React.Fragment>
    );
  }

  const getCategory = () => {
    let data = [];
    axios.get(`${apiHost}/events/categories/get/${match.params.slug}`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          data.number_id = "8" + data.id.toString();
          setPage(data);

          //setEvents(data.destinations);

          //setSearchValues({ categories: [data.id] });

          /*
          let geomarkers = [];
          data.destinations.forEach(marker => {
            geomarkers.push({ data: marker, location: new LatLng(marker.location_latitude, marker.location_longitude) })
          });
          setMarkers(geomarkers);
          */

          const after = new Date().getDate() + 180;
          const newDate = new Date().setDate(after);
          let values = {};
          values.dates = new Date().toISOString() + '|' + new Date(newDate).toISOString();
          values.categories = [data.id];
          //calendarRef.current.getApi().changeView('dayGridMonth', new Date());
          setSelectedDates([new Date(), new Date(newDate)]);
          getEvents(values);
          setSearchValues(values);

          setLoading(false);

          console.log('entry-id', data.number_id);
          console.log('entry-slug', "events/category/" + data.slug);
        }
        return res;
      })
      .catch(err => { setLoading(false); });

    return data;
  };

  const getEvents = (values = null) => {
    let data = [];
    axios.post(`${apiHost}/events/search`, values)
      .then(res => {
        data = res.data;
        setEvents([]);
        if (res.status == 200) {
          setEvents(data);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getFeaturedEvents = () => {
    let data = [];
    const values = {
      categories: [11]
    }
    axios.post(`${apiHost}/events/search`, values)
      .then(res => {
        data = res.data;
        setFeaturedEvents([]);
        if (res.status == 200) {
          setFeaturedEvents(data);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getCategories = () => {
    let data = [];
    axios.get(`${apiHost}/events/categories`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setCategories(data);
          setCategoriesLoading(false);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const showToast = (message, status) => {
    toast({
      position: "bottom",
      description: message,
      status: status,
      duration: 5000,
      isClosable: true
    });
  };

  const showNotification = (message, status) => {
    toast({
      position: "bottom",
      description: message,
      status: status,
      duration: 10000,
      isClosable: true
    });
  };

  const handleReset = (values = null) => {
    document.getElementById("category").value = "";
    const after = new Date().getDate() + 180;
    const newDate = new Date().setDate(after);
    setSelectedDates([new Date(), new Date(newDate)]);

    values.dates = new Date().toISOString() + '|' + new Date(newDate).toISOString();
    getEvents(values);

    let button = document.getElementsByClassName("fc-today-button")[0];
    button.click();

    //document.getElementById("experience").value = "";
    //document.getElementById("activity").value = "";
  };

  const handleDestinationClick = (destination) => {
    setSelectedDestination(destination);
    onOpen();
  }

  const eventClick = function (info) {
    info.jsEvent.preventDefault();
    console.log(info.event.url);
    setSelectedDestination(events[0]);
    onOpen();
  }

  const sortClick = function (order) {
    let values = searchValues;
    values.order = order;
    getEvents(values);
  }

  const onGridList = function (breakpointColumns) {
    setBreakpointColumns(breakpointColumns)
    if (breakpointColumns.default == 3) {
      setGrid(false);
    } else {
      setGrid(true);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getCategory();
    //getExperiences();
    //getActivities();
    getCategories();
    //showNotification("Search for destinations notification", "info");

    getFeaturedEvents();

    setBreakpointColumns(breakpointColumnsObj);
  }, []);

  return (
    <PageSlideFade>
      <Helmet>
        <title>{page.meta_title}</title>
        <meta name="description" content={page.meta_description} />
      </Helmet>

      {!loading && !page.slug && (
        <Box>
          <Container maxW={'5xl'}>
            <Heading
              as={'h3'}
              fontFamily="american"
              fontWeight={500}
              fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
              lineHeight={'100%'}
              textAlign="left"
              mt={{ base: 10, md: 5 }}>
              404 Page Not Found
            </Heading>
            <Box textAlign="left">
              <Button as={Link} href="/">Go to Home Page</Button>
            </Box>
          </Container>
        </Box>
      )}

      {page.slug && (
        <Box>

          {page.header_img != null ? (
            <Box className="home-header-container" backgroundImage={`url(${page.header_img})`} backgroundPosition="center center" backgroundSize="cover" minHeight="60dvh">
              <Container maxW={'5xl'}>

              </Container>
            </Box>
          ) : null}
          <Box bg="#15315A">
            <Container maxW={'5xl'}>
              <Grid templateColumns='repeat(6, 1fr)'>
                <GridItem colSpan={{ base: 12, md: 6 }}>
                  <Text
                    as="h1"
                    textAlign="left"
                    fontFamily="american"
                    fontWeight={500}
                    fontSize={{ base: '3xl', sm: '4xl', md: '5xl' }}
                    lineHeight={'100%'}
                    color="#BDECDE"
                    mt={{ base: 4, md: 0 }}
                  >{page.name}</Text>
                </GridItem>
              </Grid>
            </Container>
          </Box>

          {page.description != null ? (
          <Box minHeight="25vh" bg="#8FBD99">
            <Container maxW={'6xl'}>
              <Box pt={10} pb={10}>
                  <Text fontSize="3xl" color="white" textAlign="left">
                    {page.description}
                  </Text>
              </Box>
            </Container>
          </Box>
           ) : null}

          <Box bg="white">
            <Container maxW={'6xl'}>
              <Box className="content-block" pt={10} pb={10} textAlign="left">
                {page.content != null ? (
                  <Text>
                    {parser(page.content)}
                  </Text>
                ) : null}
              </Box>
            </Container>
          </Box>

          <Box bg="white">
            <Box>
              <SimpleGrid columns={3}>
                <Box textAlign="left">
                  <Button onClick={() => onFilterOpen()} p={5}>Filter</Button>
                </Box>
                <Box display={{ base: "none", md: "block" }}>
                  <ButtonGroup spacing='2'>
                    <Button onClick={() => onGridList(breakpointColumnsObj)} p={5}>Grid</Button>
                    <Button onClick={() => onGridList(breakpointColumnsListObj)} p={5}>List</Button>
                  </ButtonGroup>

                </Box>
                <Box display="flex" flexDirection="row" alignItems="right" >
                  <HStack marginLeft="auto" mb={5} mr={5}>
                    <Spacer />
                    <Text w="100px" textAlign="right">Sort By</Text>
                    <Select
                      onChange={(e) => {
                        sortClick(e.target.value);
                      }} w={{ base: "120px", md: "200px" }}>
                      <option value="upcoming">Latest</option>
                      <option value="oldest">Oldest</option>
                    </Select>
                  </HStack>
                </Box>
              </SimpleGrid>
            </Box>

            <SimpleGrid columns={{ base: 1, md: 1 }} spacing={10}>
              <Box p={5}>
                {loading ? null : (
                  <Masonry breakpointCols={breakpointColumns}
                    className="masonry-grid"
                    columnClassName="masonry-grid_column">

                    {events?.map((event, index) => (
                      <Box
                        w={'100%'}
                        bg="#8FBD99"
                        boxShadow={'0 0 10px 2px #ccc'}
                        rounded={'md'}
                        _hover={{
                          bg: '#F7E6CE'
                        }}
                        d="inline-block"
                        mb={5}
                        className="masonry"
                      >
                        <Box
                        >
                          {event.main_img && grid && (
                            <Link onClick={() => handleDestinationClick(event)}>
                              <Image
                                src={`${event.main_img}`}
                                layout={'fill'}
                                w={'100%'}
                                rounded={'md'}
                                borderBottomRadius={"0"}
                              />
                            </Link>
                          )}
                          <Box position="relative">
                            <Link onClick={() => handleDestinationClick(event)}>
                              <Grid templateColumns='repeat(4, 1fr)'>
                                <GridItem colSpan={1} bg="#BDEDDF" p={2} pb={0} borderBottomStartRadius={'md'}>
                                  {event.main_img && !grid && (
                                    <Link onClick={() => handleDestinationClick(event)}>
                                      <Image
                                        src={`${event.main_img}`}
                                        layout={'fill'}
                                        w={'100%'}
                                        rounded={'md'}
                                      />
                                    </Link>
                                  )}
                                  <Text className="date-month">{event.starts_month}</Text>
                                  <Text className="date-day">{event.starts_day}</Text>
                                </GridItem>
                                <GridItem colSpan={3} p={2} pb={0}>
                                  <Heading fontSize="md" fontFamily="Roboto" fontWeight="800" textAlign="left" lineHeight="115%">{event.title}</Heading>
                                  {event.summary && !grid && (
                                    <Text fontSize="1rem" textAlign="left">{event.summary}</Text>
                                  )}
                                </GridItem>
                              </Grid>
                            </Link>
                          </Box>
                        </Box>
                      </Box>
                    ))
                    }
                  </Masonry>
                )}

              </Box>
              <Box display="none">
                <Box sx={{ position: '-webkit-sticky', /* Safari */ position: 'sticky', top: '0px', }}>

                  <MapContainer center={position} zoom={12} scrollWheelZoom={false} style={{ minHeight: '100vh' }}>
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <LocationMarkers />
                  </MapContainer>

                </Box>
              </Box>
            </SimpleGrid>

          </Box>

        </Box>
      )}

      <Drawer
        isOpen={isOpen}
        size="lg"
        placement="left"
        closeOnOverlayClick={true}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent
                  motionProps={{
                    initial: 'none',
                    animate: 'none',
                    exit: 'none',
                  }}
                >
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            <Heading pb={0}>{selectedDestination.title}</Heading>
          </DrawerHeader>

          <DrawerBody>
            <Stack spacing="24px">
              <Center>
                {selectedDestination.main_img && (
                  <Box
                    backgroundImage={`${selectedDestination.main_img}`}
                    backgroundSize="cover"
                    backgroundPosition="center center"
                    height="300px"
                    width={'100%'}
                    borderBottomStartRadius={'md'}
                  />
                )}
              </Center>

              {selectedDestination.content && (
                <Box className="content-block">{parser(selectedDestination.content)}</Box>
              )}

              <Stack>
                <Center><Text>{selectedDestination.summary}</Text></Center>
                <Center><Text>{selectedDestination.address}</Text></Center>
                <Center><Link href={`tel:+1${selectedDestination.phone}`}>{selectedDestination.phone}</Link></Center>
              </Stack>

            </Stack>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px" justifyContent="flex-end">
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            {selectedDestination ? (
              <Button as={Link} href={`/events/${selectedDestination.slug}`} >More Information</Button>
            ) : null}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Drawer
        isOpen={isFilterOpen}
        size="md"
        placement="left"
        closeOnOverlayClick={true}
        onClose={onFilterClose}
      >
        <DrawerOverlay />
        <Formik
          initialValues={{ title: "", dates: "", destination: null, category: null }}
          onReset={(values, actions) => {
            handleReset(values);
          }}
          onSubmit={(values, actions) => {
            if (values.category) {
              values.categories = [values.category];
            }
            if (selectedDates) {
              if (selectedDates.length > 1) {
                values.dates = selectedDates[0].toISOString() + '|' + selectedDates[1].toISOString();
                //calendarRef.current.getApi().changeView('dayGridMonth', selectedDates[0]);
              }
            } else {
              const after = new Date().getDate() + 180;
              const newDate = new Date().setDate(after);
              values.dates = new Date().toISOString() + '|' + new Date(newDate).toISOString();
              //calendarRef.current.getApi().changeView('dayGridMonth', new Date());
            }
            getEvents(values);
            setSearchValues(values);
            actions.setSubmitting(false);
            onFilterClose();
          }}
        >
          {({ values, handleChange, handleSubmit, handleReset, isSubmitting, setFieldValue }) => {
            return (
              <form id="searchForm" autoComplete="off" onSubmit={handleSubmit} onReset={handleReset} onChange={handleChange} style={{ width: "100%" }}>
                <DrawerContent
                  motionProps={{
                    initial: 'none',
                    animate: 'none',
                    exit: 'none',
                  }}
                >

                  <DrawerCloseButton />
                  <DrawerHeader borderBottomWidth="1px">
                    <Heading pb={0}>Filter</Heading>
                  </DrawerHeader>

                  <DrawerBody>

                    <Field
                      name="dates"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl>
                          <FormLabel>Dates</FormLabel>
                          <RangeDatepicker
                            name="date-input"
                            selectedDates={selectedDates}
                            onDateChange={setSelectedDates}
                            propsConfigs={propsConfigs}
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="category"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl>
                          <FormLabel mt={2}>Category</FormLabel>
                          {experiencesloading ? (
                            <Select placeholder="All"></Select>
                          ) : (
                              <Select  {...field}
                                id="category"
                                borderRadius="15px"
                                fontSize="sm"
                                type="text"
                                placeholder="All"
                                size="lg"
                                value={values.category}
                                onChange={handleChange}>
                                {categories?.map((cat, index) => (
                                  <option key={index} value={cat.id}>{cat.name}</option>
                                ))
                                }
                              </Select>
                            )}
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="title"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl>
                          <FormLabel mt={2}>Event Organizer or Title</FormLabel>
                          <Input
                            {...field}
                            id="title"
                            borderRadius="15px"
                            fontSize="sm"
                            type="text"
                            placeholder='Annual'
                            size="lg"
                            value={values.title}
                            onChange={handleChange}
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Box height="70vh">

                    </Box>

                  </DrawerBody>

                  <DrawerFooter borderTopWidth="1px" justifyContent="flex-end">
                    <Button mr={3} onClick={onFilterClose}>
                      Close
                    </Button>
                    <Button mr={3} type="reset">Reset</Button>
                    <Button mr={3} type="submit" isLoading={isSubmitting}>Search</Button>
                  </DrawerFooter>
                </DrawerContent>
              </form>
            );
          }}
        </Formik>
      </Drawer>

    </PageSlideFade>
  );
};

export default Eventcategory;
