import * as React from "react";
import { ReactElement, useEffect, useState, useRef } from 'react';
import {
  Box,
  Stack,
  Heading,
  Text,
  Input,
  Image,
  Button,
  useColorModeValue,
  Container,
  useToast,
  Flex,
  SimpleGrid,
  StackDivider,
  Icon,
  SpaceProps,
  HStack,
  Tag,
  Link,
  Select,
  ButtonGroup,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  FormControl,
  FormLabel,
  DrawerFooter,
  useDisclosure,
  Grid,
  GridItem
} from "@chakra-ui/react";
import { PageSlideFade } from "../components/page-transitions";
import { apiHost, baseUrl } from "utils/Cons/Constants";
import axios from "axios";
import { Helmet } from "react-helmet";
import headerBg from "../assets/images/header-background.jpg";
import cloudsBg from "../assets/images/cloud-background.png";
import parser from "html-react-parser";
import Masonry from "react-masonry-css";
import "../style/style.css";
import { MotionBox } from "../components/motion";
import Slider from "react-slick";
import { Field, Formik } from "formik";

const markdownTheme = {
  p: props => {
    const { children } = props;
    return (
      <Text mb={2} fontSize={'0.8em'}>
        {children}
      </Text>
    );
  },
};

const Posts = () => {
  const toast = useToast();

  const [loading, setLoading] = useState(true);

  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);

  const [page, setPage] = useState({ fields: {} });

  const [grid, setGrid] = useState(true);
  const [breakpointColumns, setBreakpointColumns] = useState({});

  const [searchValues, setSearchValues] = useState({});


  const breakpointColumnsObj = {
    default: 4,
    1300: 3,
    900: 2
  };

  const breakpointColumnsListObj = {
    default: 2
  };

  // drawers
  const { isOpen, onOpen, onClose } = useDisclosure()
  const firstField = useRef()

  const {
    isOpen: isFilterOpen,
    onOpen: onFilterOpen,
    onClose: onFilterClose
  } = useDisclosure()

  const settingsSlickEvents = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  const getExperience = () => {
    let data = {};
    axios.get(`${apiHost}/news/list`)
      .then(res => {
        data = res.data.data;
        if (res.status == 200) {
          setPosts(data);
          setLoading(false);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getCategories = () => {
    let data = [];
    axios.get(`${apiHost}/categories/list`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setCategories(data);
          setCategoriesLoading(false);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getPage = () => {
    let data = [];
    axios.get(`${apiHost}/page/get/news`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          data.number_id = data.id.toString();
          setPage(data);
          console.log('entry-id', data.id.toString());
          console.log('entry-slug', data.slug);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const sortClick = function (order) {
    let values = searchValues;
    values.order = order;
    getExperience(values);
  }

  const onGridList = function (breakpointColumns) {
    setBreakpointColumns(breakpointColumns)
    if (breakpointColumns.default == 2) {
      setGrid(false);
    } else {
      setGrid(true);
    }
  }

  const showToast = (message, status) => {
    toast({
      position: "bottom",
      description: message,
      status: status,
      duration: 5000,
      isClosable: true
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPage();
    getExperience();
    setBreakpointColumns(breakpointColumnsObj);
  }, []);

  return (
    <PageSlideFade>
      <Helmet>
        <title>{page.meta_title}</title>
        <meta name="description" content={page.meta_description} />
      </Helmet>
      <Box className="home-header-container" backgroundImage={`url(${page.header_image_url})`} backgroundPosition="center center" backgroundSize="cover" minHeight="60vh">
        <Container maxW={'6xl'}>
          <Box display="flex" flexDirection="column" flex="1" minHeight="60vh">
            <Box mt={'auto'}>
              <Heading
                as={'h1'}
                fontFamily="american"
                fontWeight={500}
                fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                color="white"
                lineHeight={'100%'}
                textAlign="left"
                textShadow="0px 0px 5px #000"
              >
                {page.title} {' '}
              </Heading>
            </Box>
          </Box>
        </Container>
      </Box>
      {page.entry && page.entry.subtitle ? (
      <Box minHeight="25vh" bg="#8FBD99">
        <Container maxW={'6xl'}>
          <Box pt={10} pb={10}>
              <Text fontSize="5xl" color="white" textAlign="left">
                {parser(page.entry.subtitle)}
              </Text>
          </Box>
        </Container>
      </Box>
      ) : null}
      {page.entry && page.entry.content ? (
      <Box bg="white">
        <Container maxW={'6xl'}>
          <Box className="content-block" pt={10} pb={10}>
              <Text fontSize="3xl" textAlign="left">
                {parser(page.entry.content)}
              </Text>
          </Box>
        </Container>
      </Box>
      ) : null}
      <Box bg="white" display="none">
        <Box>
          <div class="events-header">
            <Heading
              as={'h2'}
              fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
              lineHeight={'110%'}
              pt={10}>
              {page.title}
            </Heading>
          </div>

          <Box p={20}>
            <Slider {...settingsSlickEvents} className="events-slider">

              {posts?.map((post, index) => (
                <div>
                  <Box bg="#8FBD99" borderRadius={10} m={3}>
                    <Link href={`${post.source_link}`} target="_blank">
                      <Image src={post.main_img} borderTopRadius={10} />
                      <Box p={5}>
                        <Heading fontSize="1.45rem" textAlign="left">{post.title}</Heading>
                      </Box>
                    </Link>
                  </Box>
                </div>
              ))}

            </Slider>
          </Box>

        </Box>

      </Box>
      <Box bg="white">
        <Box display="none">
          <SimpleGrid columns={3}>
            <Box textAlign="left">
              <Button onClick={() => onFilterOpen()} p={5}>Filter</Button>
            </Box>
            <Box display={{ base: "none", md: "block" }}>
              <ButtonGroup spacing='2'>
                <Button onClick={() => onGridList(breakpointColumnsObj)} p={5}>Grid</Button>
                <Button onClick={() => onGridList(breakpointColumnsListObj)} p={5}>List</Button>
              </ButtonGroup>

            </Box>
            <Box display="flex" flexDirection="row" alignItems="right" >
              <HStack marginLeft="auto" mb={5} mr={5}>
                <Text w="100px">Sort By</Text>
                <Select
                  onChange={(e) => {
                    sortClick(e.target.value);
                  }} w="200px">
                  <option value="updated" selected>Last Updated</option>
                  <option value="name">Business Name</option>
                </Select>
              </HStack>
            </Box>
          </SimpleGrid>
        </Box>
        <Box p={5}>
          <Masonry breakpointCols={breakpointColumns}
            className="masonry-grid"
            columnClassName="masonry-grid_column">

            {posts?.map((solution, index) => (
              <Box
                w={'100%'}
                boxShadow={'0 0 10px 2px #ccc'}
                rounded={10}
                bg="#8FBD99"
                _hover={{
                  bg: '#E0CEBE'
                }}
                d="inline-block"
                mb={5}
              >
                <Box
                >
                  <Box position="relative">
                    <Link href={`${solution.source_link}`} target="_blank">
                      {solution.main_img && grid && (
                        <Image
                          src={`${solution.main_img}`}
                          layout={'fill'}
                          w={'100%'}
                          borderTopRadius={10}
                        />
                      )}
                      <Grid templateColumns='repeat(4, 1fr)'>
                        <GridItem colSpan={1} bg="#BDEDDF" p={2} pb={0} borderBottomStartRadius={'md'}>
                          {solution.main_img && !grid && (
                            <Image
                              src={`${solution.main_img}`}
                              layout={'fill'}
                              w={'100%'}
                              borderTopRadius={10}
                            />
                          )}
                          <Text className="date-month">{solution.month}</Text>
                          <Text className="date-day">{solution.day}</Text>
                        </GridItem>
                        <GridItem colSpan={3} p={2} pb={0}>
                          <Heading fontSize="1.45rem" fontFamily="Roboto" fontWeight="800" textAlign="left" lineHeight="115%">{solution.title}</Heading>
                          {solution.main_img && !grid && (
                            <Text fontSize="1rem" textAlign="left">{solution.summary}</Text>
                          )}
                        </GridItem>
                      </Grid>
                    </Link>
                  </Box>
                </Box>
              </Box>
            ))
            }
          </Masonry>
        </Box>
      </Box>
      <Drawer
        isOpen={isFilterOpen}
        size="md"
        placement="left"
        closeOnOverlayClick={true}
        onClose={onFilterClose}
      >
        <DrawerOverlay />

        <Formik
          enableReinitialize
          initialValues={{ name: "", experience: null, activity: null, category: null }}
          onReset={(values, actions) => {
            getDestinations();
            handleReset();
            onFilterClose();
          }}
          onSubmit={(values, actions) => {
            if (values.experience) {
              values.experiences = [values.experience];
            }
            if (values.activity) {
              values.activities = [values.activity];
            }
            if (values.category) {
              values.categories = [values.category];
            }
            setSearchValues(values);
            getDestinations(values);
            onFilterClose();
            actions.setSubmitting(false);
          }}
        >
          {({ values, handleChange, handleSubmit, handleReset, isSubmitting, setFieldValue }) => {
            return (
              <form id="searchForm" autoComplete="off" onSubmit={handleSubmit} onReset={handleReset} onChange={handleChange} style={{ width: "100%" }}>
                <DrawerContent
                  motionProps={{
                    initial: 'none',
                    animate: 'none',
                    exit: 'none',
                  }}
                >

                  <DrawerCloseButton />
                  <DrawerHeader borderBottomWidth="1px">
                    <Heading pb={0}>Filter</Heading>
                  </DrawerHeader>

                  <DrawerBody>

                    <Field
                      name="category"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl>
                          <FormLabel>Category</FormLabel>
                          {categories ? (
                            <Select placeholder="All"></Select>
                          ) : (
                            <Select {...field}
                              id="category"
                              borderRadius="15px"
                              fontSize="sm"
                              type="text"
                              placeholder="All"
                              size="lg"
                              value={values.category}
                              onChange={handleChange}>
                              {categories?.map((cat, index) => (
                                <option key={index} value={cat.id}>{cat.name}</option>
                              ))}
                            </Select>
                          )}
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="name"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl>
                          <FormLabel mt={2}>Post Title</FormLabel>
                          <Input
                            {...field}
                            id="name"
                            borderRadius="15px"
                            fontSize="sm"
                            type="text"
                            placeholder='Adventure'
                            size="lg"
                            value={values.name}
                            onChange={handleChange} />
                        </FormControl>
                      )}
                    </Field>

                  </DrawerBody>

                  <DrawerFooter borderTopWidth="1px" justifyContent="flex-end">
                    <Button mr={3} onClick={onFilterClose}>
                      Close
                    </Button>
                    <Button mr={3} type="reset">Reset</Button>
                    <Button mr={3} type="submit" isLoading={isSubmitting}>Search</Button>
                  </DrawerFooter>
                </DrawerContent>
              </form>
            );
          }}
        </Formik>
      </Drawer>
    </PageSlideFade>
  );
};

export default Posts;
